import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import 'swiper/css';
import './styles'

import Swiper, { Navigation } from 'swiper';

Alpine.plugin(collapse)
window.Alpine = Alpine
Alpine.start()

const swiper = new Swiper('.swiper', {
    modules: [Navigation],
    freeMode: true,
    slidesPerView: 'auto',
    spaceBetween: 10,
    slidesPerGroupAuto: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
